import React, { useState } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "react-toggle/style.css";
import { changeLanguage } from "../../actions/store";
import config from "../../config";
import { languages } from "../../constants/language";
import { useMenu } from "../../menu-items";

const Sidebar: React.FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const ossUrl = config.ossUrl + `${config.region}/Landing/`;
	const menu = useMenu();
	const url = window.location.href;
	const hostname = new URL(url).hostname;
	const domainName = hostname.replace(/^www\./, '');

	const currentLanguage = localStorage.getItem("Language") || "en";
	const [showLanguageModal, setShowLanguageModal] = useState(false);

	const handleLanguageSelect = (lang: string) => {
		dispatch(changeLanguage(lang));
		localStorage.setItem("Language", lang);
		setShowLanguageModal(false);
	};

	return (
		<div id="sidebar" className="sidebar">
			<div className="sidebar-top">
				<a href="/">
					<Image src="/logo.png" alt="logo" width={180} style={{marginTop: 10, marginBottom: -8}} />
					<br />
					{/* <h5 className="my-1">{domainName}</h5> */}
				</a>
				<hr className="hr-line" />
				<div className="lobbyTopContainer">
					<div className="lobbyTopItem">
						<img src="/icons/player.png" alt="Playericon" className="iconPlayer" />
						<span style={{ fontWeight: '500' }}>
							{t("Player")}
						</span>
					</div>
					<div className="lobbyTopDivider" />
					<a href="https://minionpro7.com/admin/login" className="lobbyTopItem">
						<img src="/icons/member.png" alt="Membericon" className="iconMember" />
						<span style={{ color: '#898989' }}>
							{t("Member")}
						</span>
					</a>
				</div>
			</div>
			<div id="sidemenu">
				{menu.map((item, index) => {
					if (!item.access) {
						return null;
					}

					if (item.type === "link" && typeof item.value === "string") {
						return (
							<div key={index}>
								<Link to={item.value}>
									{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
									<Image
										src={ossUrl + "icons/" + item.id + ".png"}
										alt={item.id}
									/>
									{t(item.label)}
								</Link>
							</div>
						);
					} else if (
						item.type === "action" &&
						typeof item.value === "function"
					) {
						return (
							<div key={index} onClick={item.value} className="menu-div">
								{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					} else if (item.type === "dropdown") {
						return (
							// <DropdownButton
							// 	key={index}
							// 	title={
							// 		<>
							// 			<Image
							// 				src={ossUrl + "icons/" + item.id + ".png"}
							// 				alt={item.id}
							// 			/>
							// 			{t(item.label)}
							// 		</>
							// 	}
							// 	id="dropdown-menu"
							// 	className="menu-div"
							// >
							// 	{languages.map((lang, langIndex) => (
							// 		<Dropdown.Item
							// 			key={langIndex}
							// 			eventKey={langIndex}
							// 			onClick={() => {
							// 				dispatch(changeLanguage(lang.value));
							// 				localStorage.setItem("Language", lang.value);
							// 			}}
							// 		>
							// 			{lang.label}
							// 		</Dropdown.Item>
							// 	))}
							// </DropdownButton>
							<div key={index} className="menu-div" onClick={() => setShowLanguageModal(true)}>
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					}

					return null;
				})}
			</div>

			<Modal show={showLanguageModal} onHide={() => setShowLanguageModal(false)}>
				<Modal.Header closeButton>
					<div style={{ position: 'relative', width: '100%' }}>
						<div style={{ borderBottom: '1px solid #bcbcbc', textAlign: 'center' }}>
							<Modal.Title>{t("Select Language")}</Modal.Title>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center">
					{languages.map((lang, index) => (
						<Button
							key={index}
							className="w-75 my-2"
							onClick={() => handleLanguageSelect(lang.value)}
							style={{
								backgroundColor: lang.value === currentLanguage ? '#ff7f50' : '#fff', // Active color
								color: lang.value === currentLanguage ? '#fff' : '#000', // Text color for active
								border: `1px solid ${lang.value === currentLanguage ? '#ff7f50' : '#ccc'}` // Border color
							}}
						>
							{lang.label}
						</Button>
					))}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Sidebar;
